<template>
  <div>
    <my-table
      :rows="deliveryhistory"
      :columns="$t('delivery_history_table')"
      :is-loading="isLoading"
    >
      <template v-slot="{ props }">
        <span v-if="props.column.field === 'id'">
          <b-button
            variant="primary"
            :to="{ name: 'deliverysummary', params: { id: props.row.id } }"
            >{{ props.row.reference_id }}</b-button
          >
        </span>

        <span v-else-if="props.column.field === 'recipient.complete'">
          <img
            :src="props.row.client ? props.row.client.logo : ''"
            height="70px"
          />
        </span>

        <span v-else-if="props.column.field === 'date_added'">
          {{ $helpers.formatDate(props.row.date_added) }}
        </span>

        <span v-else-if="props.column.field === 'delivery_date'">
          {{ $helpers.formatDateTime(props.row.delivery_date) }}
        </span>

        <span v-else-if="props.column.field === 'status.text'">
          <b-badge class="p-1 w-100" :variant="props.row.status.color_name">
            {{ $t('delivered') }}
          </b-badge>
        </span>

        <span v-else-if="props.column.field === 'received_at'">
          <b-dropdown
            id="filter-by"
            :split-variant="
              props.row.received_at ? 'outline-success' : 'outline-primary'
            "
            :text="
              props.row.received_at
                ? $t('order_received')
                : $t('order_not_received')
            "
            :variant="props.row.received_at ? 'success' : 'primary'"
            class="w-100"
            split
          >
            <b-dropdown-item
              v-for="(item, index) in receivedOptions"
              :key="index"
              @click.prevent="updateReceivedStaus(props.row.id, item.value)"
            >
              <span>{{
                item.value ? $t('mark_as_received') : $t('mark_not_received')
              }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </span>

        <span v-else-if="props.column.field === 'price'">
          {{ $helpers.formatTotal(props.row.price) }}
        </span>

        <span v-else-if="props.column.field === 'tracking'">
          <b-button
            type="button"
            variant="primary"
            block
            @click.prevent="trackNow(props.row.id)"
            >{{ $t('src.views.pages.inside.deliveryhistory.trackN') }}</b-button
          >
        </span>

        <span v-else-if="props.column.field === 'invoice'">
          <b-button
            v-if="props.row.invoice"
            variant="primary"
            class="w-100"
            :href="props.row.invoice ? props.row.invoice.receipt : '#'"
            target="_blank"
            >{{
              $t('src.views.pages.inside.deliveryTransaction.download')
            }}</b-button
          >

          <b-button
            v-if="!props.row.invoice"
            class="w-100"
            variant="primary"
            :disabled="!props.row.payment || downloading"
            @click.prevent="handleDownloadInvoice(props.row)"
          >
            <b-spinner
              v-if="downloading && selectedRow === props.row.id"
              class="mr-50 p-0"
              small
            />
            <span v-else>{{
              $t('src.views.pages.inside.deliveryTransaction.download')
            }}</span>
          </b-button>
        </span>

        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </my-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from '@axios'
import MyTable from '@/components/MyTable.vue'

export default {
  components: {
    MyTable
  },
  data() {
    return {
      downloading: false,
      selectedRow: null,
      receivedOptions: [
        { value: 0, text: 'Mark Not Received' },
        { value: 1, text: 'Mark as Received' }
      ]
    }
  },
  computed: {
    ...mapGetters({
      deliveryhistory: 'orders/orders',
      isLoading: 'orders/isLoading'
    })
  },
  created() {
    this.fetchOrders()
  },
  methods: {
    fetchOrders() {
      this.$store.dispatch('orders/fetchOrders', {
        query: 'orders_for=b2bpro&filter_by=delivery_history'
      })
    },
    async trackNow(orderId) {
      this.$router.push(`/order-tracking/${orderId}`)
    },
    async handleDownloadInvoice(row) {
      this.downloading = true
      this.selectedRow = row.id
      const response = await axios.get(
        `/download-invoice/${
          row.logpoint_id ? row.logpoint_id : row.payment.id
        }/${row.payment_method}`,
        { responseType: 'blob' }
      )
      const fileURL = window.URL.createObjectURL(new Blob([response.data]))
      const fileLink = document.createElement('a')

      fileLink.href = fileURL
      fileLink.setAttribute(
        'download',
        `invoice-${
          row.payment.transaction_id ?? row.payment.reference_number
        }.pdf`
      )
      document.body.appendChild(fileLink)
      fileLink.click()
      this.selectedRow = null
      this.downloading = false
    },
    async updateReceivedStaus(orderId, status) {
      const response = await axios.patch(
        `/order-receives/${orderId}?order_received=${status}`
      )
      if (response.status === 200) {
        this.$swal({
          icon: 'success',
          title: 'Updated Received Status',
          text: status ? 'Order Mark As Received' : 'Order Not Received',
          customClass: {
            confirmButton: 'btn btn-success'
          }
        }).then(() => {
          this.fetchOrders()
        })
      }
    }
  }
}
</script>
